var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"content"},[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink pr-0"},[_c('h1',{staticClass:"headline",staticStyle:{"white-space":"nowrap"}},[_vm._v("Agregar Proveedor")])])],1),_c('validation-observer',{ref:"form"},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.name'),"error-messages":errors},model:{value:(_vm.provider.name),callback:function ($$v) {_vm.$set(_vm.provider, "name", $$v)},expression:"provider.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"companyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.companyName'),"error-messages":errors},model:{value:(_vm.provider.companyName),callback:function ($$v) {_vm.$set(_vm.provider, "companyName", $$v)},expression:"provider.companyName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.address'),"error-messages":errors},model:{value:(_vm.provider.address),callback:function ($$v) {_vm.$set(_vm.provider, "address", $$v)},expression:"provider.address"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"contactName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.contactName'),"error-messages":errors},model:{value:(_vm.provider.contactName),callback:function ($$v) {_vm.$set(_vm.provider, "contactName", $$v)},expression:"provider.contactName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"phoneNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.phoneNumber'),"error-messages":errors},model:{value:(_vm.provider.phoneNumber),callback:function ($$v) {_vm.$set(_vm.provider, "phoneNumber", $$v)},expression:"provider.phoneNumber"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.email'),"error-messages":errors},model:{value:(_vm.provider.emailAddress),callback:function ($$v) {_vm.$set(_vm.provider, "emailAddress", $$v)},expression:"provider.emailAddress"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('fields.website'),"prefix":"http://"},model:{value:(_vm.provider.website),callback:function ($$v) {_vm.$set(_vm.provider, "website", $$v)},expression:"provider.website"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('fields.observation')},model:{value:(_vm.provider.observation),callback:function ($$v) {_vm.$set(_vm.provider, "observation", $$v)},expression:"provider.observation"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.onCancelSelected}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.isLoading},on:{"click":_vm.onConfirmSelected}},[_vm._v(" Guardar ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }