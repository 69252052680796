<template>
    <template-layout>
        <template slot="content">
            <v-container>
                <v-row align="center">
                    <v-col class="shrink pr-0">
                        <h1 class="headline" style="white-space: nowrap">Agregar Proveedor</h1>
                    </v-col>
                </v-row>

                <validation-observer ref="form">
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" lg="6">
                                            <validation-provider name="name" v-slot="{ errors }" rules="required">
                                                <v-text-field
                                                    v-model="provider.name"
                                                    :label="$t('fields.name')"
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" lg="6">
                                            <validation-provider name="companyName" v-slot="{ errors }" rules="required">
                                                <v-text-field
                                                    v-model="provider.companyName"
                                                    :label="$t('fields.companyName')"
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12">
                                            <validation-provider name="address" v-slot="{ errors }" rules="required">
                                                <v-text-field
                                                    v-model="provider.address"
                                                    :label="$t('fields.address')"
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" lg="6">
                                            <validation-provider name="contactName" v-slot="{ errors }" rules="required">
                                                <v-text-field
                                                    v-model="provider.contactName"
                                                    :label="$t('fields.contactName')"
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" lg="6">
                                            <validation-provider name="phoneNumber" v-slot="{ errors }" rules="required">
                                                <v-text-field
                                                    v-model="provider.phoneNumber"
                                                    :label="$t('fields.phoneNumber')"
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" lg="6">
                                            <validation-provider name="email" v-slot="{ errors }" rules="required">
                                                <v-text-field
                                                    v-model="provider.emailAddress"
                                                    :label="$t('fields.email')"
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" lg="6">
                                            <v-text-field
                                                v-model="provider.website"
                                                :label="$t('fields.website')"
                                                prefix="http://"
                                            />
                                        </v-col>

                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="provider.observation"
                                                :label="$t('fields.observation')"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>

                    </v-col>
                </v-row>
                </validation-observer>

                <v-row class="pa-4">
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="onCancelSelected">
                        Cancelar
                    </v-btn>

                    <v-btn color="success" @click="onConfirmSelected" :loading="isLoading">
                        Guardar
                    </v-btn>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            TemplateLayout,
            ValidationProvider,
            ValidationObserver
        },

        props: {
            stockItem: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                amount: 0,
                comment: '',

                isLoading: false,
                dialog: false,

                provider: {}
            };
        },

        methods: {
            async onConfirmSelected(value) {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                this.isLoading = true;

                try {
                    await this.$store.dispatch('providers/add', this.provider);

                    this.$router.push({ name: 'providerList' });
                }
                catch (err) {
                    this.errors = new Map(Object.entries(err.errors));
                }
                finally {
                    this.isLoading = false;
                }
            },

            onCancelSelected(value) {
                this.$router.push({ name: 'providerList' });
            }
        }
    };
</script>
